import React                  from 'react'
import PropTypes              from 'utils/prop-types'
import cx                     from 'utils/classnames'
import moment                 from 'moment'
import {
    DayPickerSingleDateController
}                             from 'react-dates'
import {
    useContext,
    getContextState
}                             from 'entrada-ui/utils'
import FallbackCalendar       from './FallbackCalendar'
import DayPickerSingleContext from '../DayPickerSingleContext'
import styles                 from './styles.module.scss'

const DayPickerSingleCalendar = ({
    classes = {},
    className: classNameRoot,
    fallbackClassName,
    isOpen: isOpenProp,
    date,
    onCalendarDateChange: onCalendarDateChangeProp,
    onFocusChange: onFocusChangeProp,
    onOutsideClick: onOutsideClickProp,
    ...props
}) => {
    const context = useContext(DayPickerSingleContext)
    const {
        isCalendarOpen,
    } = getContextState({
        props,
        states: ['isCalendarOpen'],
        context
    })

    const isOpen = isOpenProp || isCalendarOpen

    const onCalendarDateChange = React.useCallback((date) => {
        if(context) {
            context.onCalendarDateChange(date)
        }

        if(onCalendarDateChangeProp) {
            onCalendarDateChangeProp(date)
        }
    }, [onCalendarDateChangeProp])

    const onOutsideClick = React.useCallback((ev) => {
        if(context) {
            context.onOutsideClick(ev)
        }

        if(onOutsideClickProp) {
            onOutsideClickProp(ev)
        }
    }, [onOutsideClickProp])

    let isIE = false
    // https://stackoverflow.com/a/49328524
    if(typeof window !== 'undefined') {
        isIE = /*@cc_on!@*/false || !!document.documentMode;
    }

    return (
        isOpen ?
        (isIE ?
            <FallbackCalendar
                {...props}
                className={fallbackClassName}
                date={date ? moment(date) : null}
                onDateChange={onCalendarDateChange}
                onOutsideClick={onOutsideClick}
            />
            :
            <div
                className={cx(
                    styles.root,
                    classes.root,
                    classNameRoot
                )}
            >
                <DayPickerSingleDateController
                    {...props}
                    date={date ? moment(date) : null}
                    onDateChange={onCalendarDateChange}
                    onOutsideClick={onOutsideClick}
                    hideKeyboardShortcutsPanel={true}
                    numberOfMonths={1}
                    daySize={41}
                />
            </div>
        )
        :
        null
    )
}

DayPickerSingleCalendar.propTypes = {
    // ie 2022/10/22
    startDate: PropTypes.string,
    endDate: PropTypes.string,
}

DayPickerSingleCalendar.etgName = 'DayPickerSingleCalendar'

export default DayPickerSingleCalendar
