import React                from 'react'
import ReactSelect, {
    components
}                           from 'react-select'
import cx                   from 'utils/classnames'
import {
    FormControlContext
}                           from 'entrada-ui/FormControl'
import {
    useContext,
    getContextState
}                           from 'entrada-ui/utils'
import styles               from './styles.module.scss'

const SelectInput = React.memo((props) => {
    const {
        className,
        classes = {},
        name,
        value,
        required = false,
        disabled = false,
        touched = false,
        error,
        onChange,
        onInput,
        onInputChange,
        onFocus,
        onBlur,
        loading = false,
        clearable = true,
        rtl = false,
        searchable = true,
        placeholder = '',
        options,
        'aria-describedby': ariaDescribedby,
        'aria-labelledby': ariaLabelledby,
    } = props

    const [inputValue, setInputValue] = React.useState(false)
    const context = useContext(FormControlContext)

    const customComponents = React.useMemo(() => ({
        Input: ({ autoComplete, ...props }) => (
            <components.Input {...props} aria-labelledby={ariaLabelledby} aria-describedby={ariaDescribedby} autoComplete="new-password" />
        )
    }), [ariaLabelledby, ariaDescribedby])

    const handleFocus = React.useCallback(event => {
        if(context.onFocus) {
            context.onFocus(event)
        }

        if(onFocus) {
            onFocus(event)
        }
    }, [onFocus])

    const handleBlur = React.useCallback(event => {
        if(onBlur) {
            onBlur(event)
        }
    }, [onBlur])

    const handleChange = React.useCallback(selectedOption => {
        if(onChange) {
            onChange(selectedOption)
        }
    }, [onChange])

    const handleInputChange = React.useCallback(text => {
        setInputValue(text)

        if(context) {
            if(text || value) {
                context.onFilled()
            } else {
                context.onEmpty()
            }
        }

        if(onInputChange) {
            onInputChange(text)
        }
    }, [value, onInputChange])

    React.useEffect(()=> {
        if(context) {
            if(value || inputValue) {
                context.onFilled()
            } else {
                context.onEmpty()
            }
        }
    }, [value, inputValue])

    const showError = touched && (typeof error !== 'undefined')

    return (
        <ReactSelect
            className={cx(
                className,
                styles.root,
                classes.root,
                {
                    [styles.error]: showError,
                },
                {
                    [classes.filled]: value || inputValue,
                    [classes.error]: showError
                }
            )}
            classNamePrefix="react-select"
            name={name}
            value={value}
            isDisabled={disabled}
            isLoading={loading}
            isClearable={clearable}
            isRtl={rtl}
            isSearchable={searchable}
            options={options}
            components={customComponents}
            placeholder={placeholder}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onInputChange={handleInputChange}
            onChange={handleChange}
        />
    )
})

SelectInput.etgName = 'Select'

export default SelectInput
