import React                 from 'react'
import PropTypes             from 'utils/prop-types'
import cx                    from 'utils/classnames'
import moment                from 'moment'
import {
    DayPickerRangeController
}                            from 'react-dates'
import {
    useContext,
    getContextState
}                            from 'entrada-ui/utils'
import DayPickerRangeContext from '../DayPickerRangeContext'
import styles                from './styles.module.scss'

const DayPickerRangeCalendar = ({
    classes = {},
    className: classNameRoot,
    isOpen: isOpenProp,
    startDate,
    endDate,
    onCalendarDatesChange: onCalendarDatesChangeProp,
    onFocusChange: onFocusChangeProp,
    onOutsideClick: onOutsideClickProp,
    ...props
}) => {
    const context = useContext(DayPickerRangeContext)
    const {
        isCalendarOpen,
        focusedInput,
    } = getContextState({
        props,
        states: ['isCalendarOpen', 'focusedInput'],
        context
    })

    const isOpen = isOpenProp !== undefined ? isOpenProp : isCalendarOpen

    const onCalendarDatesChange = React.useCallback(({ startDate, endDate }) => {
        if(context) {
            context.onCalendarDatesChange({ startDate, endDate })
        }

        if(onCalendarDatesChangeProp) {
            onCalendarDatesChangeProp({ startDate, endDate })
        }
    }, [context, onCalendarDatesChangeProp])

    const onDateFocusChange = React.useCallback((focusedInput) => {
        if(context) {
            context.onDateFocusChange(focusedInput)
        }

        if(onFocusChangeProp) {
            onFocusChangeProp(focusedInput)
        }
    }, [context, onFocusChangeProp])

    const onOutsideClick = React.useCallback((ev) => {
        if(!context) {
            context.onOutsideClick(ev)
        }

        if(onOutsideClickProp) {
            onOutsideClickProp(ev)
        }
    }, [context, onOutsideClickProp])

    return (
        isOpen ?
        <div
            className={cx(
                styles.root,
                classes.root,
                classNameRoot
            )}
        >
            <DayPickerRangeController
                startDate={startDate ? moment(startDate) : null}
                endDate={endDate ? moment(endDate) : null}
                onDatesChange={onCalendarDatesChange}
                onFocusChange={onDateFocusChange}
                onOutsideClick={onOutsideClick}
                hideKeyboardShortcutsPanel={true}
                focusedInput={focusedInput}
                minimumNights={0}
                numberOfMonths={1}
            />
        </div>:
        null
    )
}

DayPickerRangeCalendar.propTypes = {
    // ie 2022/10/22
    startDate: PropTypes.string,
    endDate: PropTypes.string,
}

DayPickerRangeCalendar.etgName = 'DayPickerRangeCalendar'

export default DayPickerRangeCalendar
