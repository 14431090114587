import React                from 'react'
import cx                   from 'utils/classnames'
import FormControl          from 'entrada-ui/FormControl'
import FormHelpText         from 'entrada-ui/FormHelpText'
import TextFieldInput       from 'entrada-ui/TextField/TextFieldInput'
import styles               from './styles.module.scss'

const PickerInput = (props) => {
    const {
        classes = {},
        className: classNameRoot,
        id,
        label,
        StartIcon,
        touched,
        error,
        disabled,
        required,
        LabelProps,
        InputProps,
        helperText,
        onMetaChange,
        formControlRef: formControlRefProp,
        // FarePicker specific
        onClose,
        children,
        fullWidth,
        'aria-label': ariaLabel,
        ApplyBtn,
        ...restProps
    } = props

    const InputLabelId = id ? `${id}-input-label` : undefined
    const FormHelpTextId = id && (helperText || (touched && error)) ? `${id}-help-text` : undefined

    const iconSize = StartIcon && StartIcon.props.fontSize

    const InputClasses = InputProps ? InputProps.classes : {}

    const formControlRef = React.useRef(null)
    const [open, setOpen] = React.useState(false)

    React.useEffect(() => {
        const handleClickWindow = (ev) => {
            if(open && !formControlRef.current.contains(ev.target)) {
                setOpen(false)
                if(onClose) {
                    onClose(ev)
                }
            }
        }

        const handleFocusWindow = (ev) => {
        }

        window.addEventListener('click', handleClickWindow)
        window.addEventListener('focus', handleFocusWindow)

        return () => window.removeEventListener('click', handleClickWindow) && window.removeEventListener('focus', handleFocusWindow)
    }, [open, onClose, formControlRef])

    const handleFocus = (ev) => {
        setOpen(true)
    }

    const handleClickApply = (ev) => {
        setOpen(false)
    }

    const handleClickCapture = (ev) => ev.stopPropagation()

    return (
        <FormControl
            ref={formControlRefProp || formControlRef}
            className={cx(
                styles.root,
                classes.root,
                classNameRoot
            )}
            onMetaChange={onMetaChange}
            touched={touched}
            error={error}
            disabled={disabled}
            required={required}
            fullWidth={fullWidth}
        >
            <FormControl.InputArea>
                <FormControl.Label
                    StartIcon={StartIcon}
                    id={InputLabelId}
                    aria-describedby={FormHelpTextId}
                    {...LabelProps}
                />

                <TextFieldInput
                    {...restProps}
                    {...InputProps}
                    classes={{
                        root: cx(
                            styles.input,
                            StartIcon && styles[`input-with-icon-${iconSize}`],
                            InputClasses.root
                        ),
                        filled: cx(
                            InputClasses.filled
                        ),
                    }}
                    aria-labelledby={InputLabelId}
                    aria-describedby={FormHelpTextId}
                    // Only add aria-label if there isn't a aria-labelledby value
                    aria-label={!InputLabelId ? ariaLabel : undefined}
                    type="text"
                    onFocus={handleFocus}
                    value={label}
                    readOnly
                />
            </FormControl.InputArea>

            <div className={cx(styles.tooltip, open && styles.open)} onClick={handleClickCapture}>
                {children}

                {ApplyBtn &&
                <div>
                    {React.cloneElement(ApplyBtn, {
                        className: cx(styles.applyBtn, ApplyBtn.props.className),
                        onClick: handleClickApply
                    })}
                </div>
                }
            </div>

            <FormHelpText id={FormHelpTextId}>
                {helperText}
            </FormHelpText>
        </FormControl>
    )
}

export default PickerInput
