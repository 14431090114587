import React          from 'react'
import Typography     from 'entrada-ui/Typography'
import { FaPhone }    from 'react-icons/fa'
import Link           from 'components/Link'
import styles         from './styles.module.scss'

const PhoneContactBlock = () => {
  
  return (
      <div className={styles.wrap}>
        <Typography variant="h4" color="brand-color-2">
          <FaPhone className={styles.phone} /> 
          <a className={styles.phoneLink} href="tel:0508365744">0508 DOLPHINS (365 744)</a>
        </Typography>

        <Typography variant="body">
          International Calls <Link to={'tel:006493576032'}>(+64) 9357 6032</Link> <br />
          We are open from 6:30am - 8pm daily (NZDT) <br />
          Email <a className={styles.emailLink} href="mailto:bookings@awads.co.nz">bookings@awads.co.nz</a> <br />
          We aim to reply within 24 hours
        </Typography>

        <Link className="btn-secondary d-inline-block mt-3" to={'/contact'}>
            Contact us
        </Link>
      </div>
  )
}

export default PhoneContactBlock
