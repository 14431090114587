import React                from 'react'
import Img                    from "gatsby-image"
import cx                   from 'utils/classnames'
import { filter, map }              from 'lodash-es'
import {
  FaRegClock,
  FaCheck,
  FaShuttleVan,
  FaRegCommentDots,
  FaAccessibleIcon
}                           from 'react-icons/fa'
import {
  Container,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
}                           from 'react-bootstrap'
import {
  StickyContainer,
  Sticky
}                           from 'react-sticky'
import RawHtml              from 'entrada-ui/RawHtml'
import Typography           from 'entrada-ui/Typography'
import {
  useWindowSize
}                           from 'utils'
import Accordion            from 'entrada-ui/Accordion'
import BookingWizard         from 'components/BookingWizard'
import Gallery              from "components/Gallery"
import Link                 from 'components/Link'
import PhoneContactBlock    from 'components/PhoneContactBlock'
import PreviewLoader        from 'components/PreviewLoader'
import TourCards            from 'components/TourCards'
import PageTitle            from 'components/PageTitle'
import Seo                  from 'components/Seo'

import styles               from './styles.module.scss'

const TourCard = TourCards.CardWp

let prevScroll = 0

const TourTemplate = (props) => {
  const {
    pageContext: { data }
  } = props

  
  const {
    siteMetadata,
    yoast_meta,
    wordpress_id,
    title,
    slug,
    content,
    // destination,
    acf: {
      tour_code,
      gallery,
      accordion,
      related_contents,
      price,
      booking_alert,
      booking_info
    }
  } = data

  const wSize = useWindowSize()

  const galleryImages = gallery.map(item => item.localFile.childImageSharp.fluid)

  const ref = React.useRef(null)
  const [headerOffsetTop, setHeaderOffsetTop] = React.useState(0)
  const [scrollingUp, setScrollingUp] = React.useState(false)
  const [currentPageIndex, setCurrentPageIndex] = React.useState(0)

  React.useEffect(() => {
      const handleScrollWindow = (ev) => {
          const newScrollingUp = prevScroll > window.pageYOffset
          if(newScrollingUp !== scrollingUp) {
            setScrollingUp(newScrollingUp)
          }

          prevScroll = window.pageYOffset
      }

      window.addEventListener('scroll', handleScrollWindow)

      return () => window.removeEventListener('scroll', handleScrollWindow)
  }, [scrollingUp, headerOffsetTop])

  React.useEffect(() => {
      if(ref) {
        setHeaderOffsetTop(ref.current.getBoundingClientRect().top)
      }
  }, [ref])

  return (
    <>
      <Seo meta={yoast_meta} siteMetadata={siteMetadata} />
      <PreviewLoader {...props} />
      <PageTitle title={title} />
      <div className={styles.body}>

        <Container ref={ref}>
            <StickyContainer>
              <Row>
                <Col xs={12} lg={7} xl={8} className="pl-0 pr-0 pl-lg-2 pr-lg-2">
                  <Gallery images={galleryImages} />
                </Col>

                <Col xs={12} lg={5} xl={4} className="position-relative">
                  <Sticky bottomOffset={75} disableCompensation>
                    {({ style, isSticky, distanceFromTop, calculatedHeight }) => (
                      <div
                        className={cx(
                          styles.bookingWizardSticky,
                          (!wSize.isMobile && !wSize.isTablet) && isSticky && styles.sticky,
                          currentPageIndex === 1 && styles.optionsStep,
                          scrollingUp && styles.scrollingUp,
                        )}
                        style={(!wSize.isMobile && !wSize.isTablet) ? {
                          ...style,
                          // top: (scrollingUp ? headerOffsetTop : style.top)
                        } : undefined}
                        >
                        {/* Temporarily deactivate PIH booking module. We'll use CustomLinc as MVP */}

                        <div className={styles.tmpBookingWrapper}>
                          <div className={styles.tmpBookingTitle}>
                            From <span className={styles.tmpPrice}>${price}</span> NZD
                          </div>
                          <div className={styles.bookingInfo}>
                            <RawHtml html={booking_info} />
                          </div>
                          <Link className={cx('btn btn-primary btn-block btn-lg', styles.bookNow)} to="https://secure.whalewatchingauckland.com/bookings.aspx?cat=AWAD&amp;source=AWADWEB">
                          Book now
                          </Link>
                        </div> 

                        {/* Temporarily deactivate PIH booking module. We'll use CustomLinc as MVP */}
                        {/* {booking_alert && <div className={styles.cardStamp}>{booking_alert}</div>}
                        <BookingWizard
                          className={styles.bookingWizard}
                          tourCode={tour_code}
                          tourSellingFast={false}
                          destination={destination}
                          relatedTours={[]}
                          cmsTour={data}
                          onPageChange={(page) => setCurrentPageIndex(page)}
                        /> */}
                      </div>
                    )}
                  </Sticky>
              </Col>
            </Row>

            <div className={styles.content}>
              <Row>
                <Col xs={12} lg={7} xl={8}>
                  <Typography variant="h3">
                    Overview
                  </Typography>
                  <RawHtml html={content} />
                </Col>
              </Row>
            </div>

            <Row>
              <Col xs={12} lg={7} xl={8}>
                <Accordion className={styles.accordion}>
                  {map(accordion, (item, idx) => (
                    <Accordion.Item className={styles.tourAccordionItem} key={idx}>
                      <Accordion.Toggler>
                        <Accordion.Title className="text-color-brand-color text-bold">{item.title}</Accordion.Title>
                      </Accordion.Toggler>

                      <Accordion.Content>
                        <RawHtml html={item.body} />
                      </Accordion.Content>
                    </Accordion.Item>
                  ))}
                </Accordion>

                <PhoneContactBlock />
              </Col>
            </Row>

          </StickyContainer>
        </Container>

        {/* TODO: create a 'PageCard' component for this below */}
        <div className={styles.relatedTours}>
          <Container>
            <Typography className={styles.relatedToursTitle} variant="h4">
              More Info
            </Typography>
            <Row className="related-pages">

              {map(related_contents, (item, idx) => (
              <Col xs={12} md={6} xl={3} key={idx}>
                <div className="related-page-card">
                  {item.acf.featured_image
                    ? <Link to={item.path}><Img fluid={item.acf.featured_image.localFile.childImageSharp.fluid} className="related-page-img" /></Link>
                    : <Link to={item.path}><div className="related-page-empty-img"></div></Link>
                  }
                  <div className="related-page-info">
                    <Link className="related-page-link" to={item.path}>
                      {/* TODO: fix these 'replace' below */}
                      {item.title.replace('&#038;', '&').replace('&#39', "'").replace('&#8216;', "'").replace('&#8216;', "'").replace('&#8211;', '-').replace('&#8211;', '-').replace('&#8217;', "'").replace('&#8217;', "'")}
                    </Link>
                    {item.excerpt &&
                    <RawHtml className="related-page-excerpt" html={item.excerpt} />
                    }
                  </div>
                </div>
              </Col>
              ))}

            </Row>
          </Container>
        </div>

      </div>
    </>
  )
}

export default TourTemplate
