import React                  from 'react'
// Required by 'react-dates':
// https://github.com/airbnb/react-dates#initialize
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import PropTypes              from 'utils/prop-types'
import {
    useContext,
}                             from 'entrada-ui/utils'
import DayPickerSingleInput   from './Single/DayPickerSingleInput'
import DayPickerRangeInput    from './Range/DayPickerRangeInput'
import DayPickerRangeContext  from './Range/DayPickerRangeContext'

const DayPicker = ({
    start,
    end,
    ...props
}) => {

    const rangeContext = useContext(DayPickerRangeContext)
    const isRange = !!rangeContext || start || end

    const Component = isRange ? DayPickerRangeInput : DayPickerSingleInput
    const memoProps = React.useMemo(() => {
        // We only pass down start/end to the Component if it's a range
        return ({
            start: isRange ? start : undefined,
            end: isRange ? end : undefined,
        })
    }, [
        isRange,
        start,
        end
    ])

    return (
        <Component
            {...memoProps}
            {...props}
        />
    )
}

DayPicker.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    variant: PropTypes.string,

    start: PropTypes.bool,
    end: PropTypes.bool,
    onFocus: PropTypes.func
}

export default DayPicker
