import React                from 'react'
import PropTypes            from 'utils/prop-types'
import cx                   from 'utils/classnames'
import FormControl          from 'entrada-ui/FormControl'
import FormHelpText         from 'entrada-ui/FormHelpText'
import CheckboxInput        from './CheckboxInput'
import styles               from './styles.module.scss'

const Checkbox = React.memo((props) => {
    const {
        id,
        classes = {},
        className: classNameRoot,
        label,
        fullWidth,
        touched,
        error,
        disabled,
        required,
        formControlRef,
        InputLabelProps,
        helperText,
        onMetaChange,
        ...restProps
    } = props

    const InputLabelId = id ? `${id}-checkbox-label` : undefined
    const FormHelpTextId = id && (helperText || (touched && error)) ? `${id}-help-text` : undefined

    return (
        <FormControl
            ref={formControlRef}
            className={cx(
                styles.root,
                classes.root,
                classNameRoot
            )}
            onMetaChange={onMetaChange}
            fullWidth={fullWidth}
            touched={touched}
            error={error}
            disabled={disabled}
            required={required}
        >
            <FormControl.InputArea
                className={styles.inputArea}
            >
                {label &&
                <FormControl.Label
                    className={styles.label}
                    label={label}
                    id={InputLabelId}
                    aria-describedby={FormHelpTextId}
                    {...InputLabelProps}
                />
                }

                <CheckboxInput
                    aria-labelledby={InputLabelId}
                    aria-describedby={FormHelpTextId}
                    classes={{
                        filled: styles['filled']
                    }}
                    touched={touched}
                    error={error}
                    {...restProps}
                />
            </FormControl.InputArea>

            <FormHelpText id={FormHelpTextId}>
                {helperText}
            </FormHelpText>
        </FormControl>
    )
})

Checkbox.propTypes = {
    id: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,

    name: PropTypes.string,
    value: PropTypes.oneOfType([
        () => null,
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })
    ]),
    onChange: PropTypes.func,

    required: PropTypes.bool,
    disabled: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([
        () => undefined,
        PropTypes.bool,
        PropTypes.string,
    ]),

    rtl: PropTypes.bool,

    label: PropTypes.string,

    InputLabelProps: PropTypes.object,
    // FormHelpTextProps: PropTypes.object,

    helperText: PropTypes.node,

    /**
     * This is useful if we want to render the field error outside of the component.
     */
    onMetaChange: PropTypes.func,

    children: PropTypes.node,
}

export default Checkbox
