import React                from 'react'
import PropTypes            from 'utils/prop-types'
import { includes }         from 'lodash-es'
import {
    START_DATE,
    END_DATE
}                           from 'react-dates/constants'
import {
    isEtgElement,
}                           from 'entrada-ui/utils'
import DayPickerSingleContext    from '../DayPickerSingleContext'

const DayPickerSingleProvider = ({
    fieldName,
    onFocusDateInput,
    onCalendarDateChange,
    onOutsideClick,

    children,
    ...props
}) => {
    const [focused, setFocused] = React.useState(false)
    const [isCalendarOpen, setCalendarOpen] = React.useState(false)

    // Check if one of the children is a DayPickerRangeCalendar, if so
    // then the DayPicker will "message" this component; if not then
    // the DayPicker will render its own Calendar.
    let isCalendarControlled = false
    React.Children.forEach(children, child => {
        if (isEtgElement(child, ['DayPickerRangeCalendar'])) {
            isCalendarControlled = true;
        }
    })

    const handleFocusDateInput = React.useCallback(() => {
        setFocused(true)
        setCalendarOpen(true)

        if(onFocusDateInput) {
            onFocusDateInput()
        }
    }, [setFocused, setCalendarOpen, onFocusDateInput])

    const handleCalendarDateChange = React.useCallback((date) => {
        if(onCalendarDateChange) {
            onCalendarDateChange(date)
        }

        setCalendarOpen(false)
    }, [onCalendarDateChange])


    const handleOutsideClick = React.useCallback((ev) => {
        if(ev.target.name === fieldName) {
            return
        }

        setFocused(false)
        setCalendarOpen(false)

        if(onOutsideClick) {
            onOutsideClick(ev)
        }
    }, [fieldName, onOutsideClick])

    const childContext = React.useMemo(() => ({
        isCalendarControlled,
        focused,
        isCalendarOpen,
        onFocusDateInput: handleFocusDateInput,
        onCalendarDateChange: handleCalendarDateChange,
        onOutsideClick: handleOutsideClick
    }), [
        isCalendarControlled,
        focused,
        isCalendarOpen,
        handleFocusDateInput,
        handleCalendarDateChange,
        handleOutsideClick
    ])

    return (
        <DayPickerSingleContext.Provider value={childContext}>
            {children}
        </DayPickerSingleContext.Provider>
    )
}


DayPickerSingleProvider.propTypes = {
    fieldName: PropTypes.string.isRequired,

    onFocusDateInput: PropTypes.func,
    onDateFocusChange: PropTypes.func,
    onCalendarDateChange: PropTypes.func.isRequired,
    onOutsideClick: PropTypes.func,

    children: PropTypes.node.isRequired,
}

export default DayPickerSingleProvider
