import React, { lazy, Suspense } from 'react'
import cx                        from 'utils/classnames'
import moment                    from 'moment'
import styles                    from './styles.module.scss'

const Calendar = lazy(() => import('react-calendar'))

const FallbackCalendar = (props) => {
    const {
        date,
        onDateChange,
        onOutsideClick,
        isDayBlocked
    } = props

    const ref = React.useRef(null)

    const handleClickWindow = (ev) => {
        if (ref.current && !ref.current.contains(ev.target)) {
            onOutsideClick(ev)
        }
    }

    React.useEffect(() => {
        window.removeEventListener('click', handleClickWindow)
        window.addEventListener('click', handleClickWindow)

        return () => window.removeEventListener('click', handleClickWindow)
    }, [handleClickWindow])

    return (
        <div className={styles.fallbackCalendar} ref={ref}>
            <Suspense fallback={<></>}>
                <Calendar
                    value={date ? date.toDate() : new Date()}
                    onChange={(date, ev) => onDateChange(date)}
                    tileDisabled={({activeStartDate, date, view }) => isDayBlocked(date)}
                />
            </Suspense>
        </div>
    )
}

export default FallbackCalendar
