import React                from 'react'
import cx                   from 'utils/classnames'
import {
    FormControlContext
}                           from 'entrada-ui/FormControl'
import {
    useContext,
    getContextState
}                           from 'entrada-ui/utils'
import styles               from './styles.module.scss'

const CheckboxInput = React.memo((props) => {
    const {
        classes = {},
        name,
        value: checked,
        required = false,
        disabled = false,
        touched = false,
        error,
        onChange,
        onInput,
        onInputChange,
        onFocus,
        onBlur,
        loading = false,
        clearable = true,
        rtl = false,
        searchable = true,
        placeholder = '',
        options,
        'aria-describedby': ariaDescribedby,
        'aria-labelledby': ariaLabelledby,
    } = props

    const [inputValue, setInputValue] = React.useState(false)
    const context = useContext(FormControlContext)

    const handleFocus = React.useCallback(event => {
        if(context.onFocus) {
            context.onFocus(event)
        }

        if(onFocus) {
            onFocus(event)
        }
    }, [onFocus])

    const handleBlur = React.useCallback(event => {
        if(onBlur) {
            onBlur(event)
        }
    }, [onBlur])

    const handleChange = React.useCallback(ev => {
        if(onChange) {
            onChange(ev.target.checked)
        }
    }, [onChange])

    const handleClick = React.useCallback(ev => {
        if(onChange) {
            onChange(!checked)
        }
    }, [onChange])

    // const handleChange = React.useCallback(ev => {
    //     if(context) {
    //         if(checked) {
    //             context.onFilled()
    //         } else {
    //             context.onEmpty()
    //         }
    //     }
    // }, [checked, onInputChange])

    // React.useEffect(()=> {
    //     if(context) {
    //         if(value || inputValue) {
    //             context.onFilled()
    //         } else {
    //             context.onEmpty()
    //         }
    //     }
    // }, [value, inputValue])

    const showError = touched && (typeof error !== 'undefined')

    return (
        <div
            className={cx(
                styles.root,
                classes.root,
                {
                    [styles.checked]: checked,
                    [styles.error]: showError,
                },
                {
                    [classes.checked]: checked,
                    [classes.error]: showError
                }
            )}
            onClick={handleClick}
        >
            <input
                type="checkbox"
                name={name}
                checked={checked}
                onChange={handleChange}
            />
        </div>
    )
})

CheckboxInput.etgName = 'Checkbox'

export default CheckboxInput
