import React                  from 'react'
import cx                     from 'utils/classnames'
import {
    Button
}                             from 'react-bootstrap'
import Modal                  from 'components/Modal'

const ServerErrorModal = ({
    show = false
}) => {
    const reloadPage = () => {
        window.location.reload()
    }

    return (
        <Modal
            show={show}
            onHide={() => {/* Disable hidding modal */}}
        >
            <Modal.Header>
                <Modal.Title>
                    Oops! Something went wrong with the request
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="pt-4 pb-4">
                <p className="pt-2 pb-2">
                    Please try again, if the problem persists please call us: <a href="tel:0508365744">0508 DOLPHINS (365 744)</a>
                </p>

                <div className="text-right">
                    <Button className="text-uppercase" variant="primary" size="lg" href="#" onClick={reloadPage}>
                        Try again
                    </Button >
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ServerErrorModal
