import Input     from './DayPickerSingleInput'
import Calendar  from './DayPickerSingleCalendar'
import Context   from './DayPickerSingleContext'
import Provider  from './DayPickerSingleProvider'

const Single = {
    Input,
    Calendar,
    Context,
    Provider
}

export default Single
