import React from 'react'
import cx                     from 'utils/classnames'
import PropTypes from 'utils/prop-types'
import styles from './styles.module.scss'

const FareCounter = ({
    name,
    id,
    label,
    sublabel,
    value,
    min,
    max,
    disabled,
    addLabel,
    removeLabel,
    onChange,
    ...restProps
}) => (
    <div className={styles.root}>
        <div className={styles.labelCol}>
            <label className={styles.label} htmlFor={id}>{label}</label>
            <span className={styles.sublabel} htmlFor={id}>{sublabel}</span>
        </div>

        <div className={styles.actions}>
            <button
                className={cx(styles.button, styles.minusBtn)}
                type="button"
                onClick={() => onChange(value - 1)}
                disabled={value <= min}
                aria-label={removeLabel}
            >

            </button>
                <input
                    className={styles.input}
                    name={name}
                    id={id}
                    value={value}
                    tabIndex="-1"
                    readOnly
                />
            <button
                className={cx(styles.button, styles.plusBtn)}
                type="button"
                onClick={() => onChange(value + 1)}
                disabled={value >= max}
                aria-label={addLabel}
            >
            </button>
        </div>
    </div>
)

FareCounter.defaultProps = {
    min: 0,
    disabled: false
}

FareCounter.propTypes = {
    label: PropTypes.string.isRequired,
    addLabel: PropTypes.string.isRequired,
    removeLabel: PropTypes.string.isRequired,
}

export default FareCounter



