import React                  from 'react'
import {
    keyBy,
    identity,
    mapValues,
    pickBy,
    reduce
}                             from 'lodash-es'
import cx                     from 'utils/classnames'
import {
    START_DATE,
}                             from 'react-dates/constants'
import gql                    from 'graphql-tag'
import moment                 from 'moment'
import {
    Field,
    Form,
}                             from 'react-final-form'
import {
    FaBurn,
    FaUser,
    FaRegCalendarAlt,
}                             from 'react-icons/fa'
import {
    useContext,
    getContextState
}                             from 'entrada-ui/utils'
import {
    Button,
}                             from 'react-bootstrap'
import IEDatePicker           from 'react-datepicker'
import Typography             from 'entrada-ui/Typography'
import Icon                   from 'entrada-ui/Icon'
import FarePicker             from 'entrada-ui/FarePicker'
import FareCounter            from 'entrada-ui/FareCounter'
import DayPickerSingle        from 'entrada-ui/DayPicker/Single'
import DayPicker              from 'entrada-ui/DayPicker'
import Link                   from 'components/Link'
import Modal                  from 'components/Modal'
import ServerErrorModal       from 'components/ServerErrorModal'
import TourCards              from 'components/TourCards'
import BookingWizard          from '../'
import { useLazyQuery }       from 'utils'
import styles                 from './styles.module.scss'
import                       "react-datepicker/dist/react-datepicker.css"

const TourCard = TourCards.CardWp

// Date with some results
// const TODAY_DATE = moment('2019-12-06').format('YYYY-MM-DD')

const TODAY_DATE = moment().format('YYYY-MM-DD')

const GET_TOUR = gql`
query($tourCode: ID!) {
    getTour(agentID: ${process.env.AGENT_ID}, tourCode: $tourCode) {
        code
        destination {
          name
          unLocode
          countryCode
        }
      }
}
`

const GET_AVAILABILITY_FOR_MONTH = gql`
query($tourCode: ID!, $departureDate: String!, $arrivalDate: String!) {
    getAvailabilityForRange(
        agentID: ${process.env.AGENT_ID}
        tourCode: $tourCode
        departureDate: $departureDate
        arrivalDate: $arrivalDate
    ) {
        startDate
        maxAvailability
        currentAvailability
    }
}
`

const GET_AVAILABILITY = gql`
  query($tourCode: ID!, $departureDate: String!) {
    getAvailability(agentID: ${process.env.AGENT_ID}, tourCode: $tourCode, departureDate: $departureDate) {
      tourCode
      startDate
      maxAvailability
      currentAvailability
    }
  }
`

const GET_PICKUPS = gql`
query($tourCode: ID!, $departureDate: String!) {
    getPickups(agentID: ${process.env.AGENT_ID}, tourCode: $tourCode, departureDate: $departureDate) {
        id
        code
        from
        time
        name
        description
    }
}`

const GET_DROPOFFS = gql`
query($tourCode: ID!, $departureDate: String!) {
    getDropoffs(agentID: ${process.env.AGENT_ID}, tourCode: $tourCode, departureDate: $departureDate) {
        id
        code
        at
        time
        name
        description
    }
}`

const GET_OPTIONS = gql`
query($tourCode: ID!, $departureDate: String!) {
    getOptions(agentID: ${process.env.AGENT_ID}, tourCode: $tourCode, departureDate: $departureDate) {
        id
        code
        type
        description
        category
        title
        prices {
            numPassengers
            passengerType
            priceLabel
            price
            code
        }
    }
}
`

const GET_TOUR_PRICING = gql`
query($tourCode: ID!, $departureDate: String!) {
    getTourPricing(agentID: ${process.env.AGENT_ID}, tourCode: $tourCode, departureDate: $departureDate) {
        priceLabel
        code
        name
        terms
        termsCode
        price
        note
        passengerType
    }
}
`

let prevScroll = 0

const BookingStepSearch = (props) => {
    const context = useContext(BookingWizard.Context)

    const {
        cmsTour,
        tourCode,
        tourSellingFast,
        destination,
        relatedTours,
        price,
        nextPage,
        setCurrentBookingTour
    } = getContextState({
        props,
        states: [
            'cmsTour',
            'tourCode',
            'tourSellingFast',
            'destination',
            'relatedTours',
            'price',
            'nextPage',
            'setCurrentBookingTour'
        ],
        context
    })

    const [availabilityPerDay, setAvailabilityPerDay] = React.useState({})
    const [currentMonth, setCurrentMonth] = React.useState(TODAY_DATE)
    const [showSoldOutModal, setShowSoldOutModal] = React.useState(false)
    const [showServerErrorModal, setShowServerErrorModal] = React.useState(false)

    const getAvailabilityForMonth = useLazyQuery(GET_AVAILABILITY_FOR_MONTH)
    const getAvailability = useLazyQuery(GET_AVAILABILITY)
    const getTour = useLazyQuery(GET_TOUR)
    const getPickups = useLazyQuery(GET_PICKUPS)
    const getDropoffs = useLazyQuery(GET_DROPOFFS)
    const getOptions = useLazyQuery(GET_OPTIONS)
    const getTourPricing = useLazyQuery(GET_TOUR_PRICING)


    const isSellingFast = (date) => {
        const availability = availabilityPerDay[moment(date).format('YYYY-MM-DD')]

        if (availability) {
            // If not available seats, we are not "selling fast"
            if(availability.maxAvailability === 0) {
                return false
            }

            return availability.maxAvailability <= 18// tourSellingFast
        }

        return false
    }

    const isSoldOut = (date) => {
        const availability = availabilityPerDay[moment(date).format('YYYY-MM-DD')]

        if (availability) {
            return availability.maxAvailability === 0
        }

        return false
    }

    const handleSubmit = (searchCriteria) => {
        let departureDate = searchCriteria['departure-date']

        if(typeof searchCriteria['departure-date'] !== 'string') {
            departureDate = moment(searchCriteria['departure-date']).format('YYYY-MM-DD')
        }

        delete searchCriteria['departure-date']

        getAvailability({
            ...searchCriteria,
            departureDate
        }).then(({ data }) => {
            const { ADULT, CHILD, INFANT } = searchCriteria
            const totalPassengers = ADULT + CHILD + INFANT
            const isAvailable = !!data.getAvailability && data.getAvailability.currentAvailability >= totalPassengers

            if(isAvailable) {
                const availability = data.getAvailability

                getTour({
                    tourCode
                }).then(({ data }) => {
                    const tour = data.getTour

                    // Get all the required data to be displayed on the Cart step (CheckoutItem's)
                    tour.tourName = cmsTour.acf.tour_name
                    tour.tourDurationHours = parseInt(cmsTour.acf.tour_duration_hours || 0)
                    tour.tourDurationMinutes = parseInt(cmsTour.acf.tour_duration_minutes || 0)
                    tour.cardImage = (cmsTour.acf.card_image?cmsTour.acf.card_image.localFile.childImageSharp.fluid:undefined)
                    tour.fares = pickBy({
                        ADULT,
                        CHILD,
                        INFANT
                    }, identity)

                    const promises = [
                        getTourPricing({
                            tourCode,
                            departureDate
                        }).then((res) => {
                            const { data } = res
                            // Convert the result of the query in a more convenient format like this:
                            // [passengerType]: [unit price]
                            // {
                            //     ADULT: 120,
                            //     CHILD: 80
                            // }
                            tour.pricing = mapValues(keyBy(data.getTourPricing, 'passengerType'), 'price')
                        }),

                        getOptions({
                            tourCode,
                            departureDate
                        }).then(({ data }) => {
                            tour.options = data.getOptions
                        }),

                        getPickups({
                            tourCode,
                            departureDate
                        }).then(({ data }) => {
                            tour.pickups = data.getPickups
                        }),

                        getDropoffs({
                            tourCode,
                            departureDate
                        }).then(({ data }) => {
                            tour.dropoffs = data.getDropoffs
                        })
                    ]

                    Promise.all(promises).then(() => {
                        tour.availability = availability

                        const {
                            ADULT, CHILD, INFANT,
                            ...restCriteria
                        } = searchCriteria
                        tour.searchCriteria = {
                            // Remove the fares that are falsy
                            searchFares: pickBy({
                                ADULT,
                                CHILD,
                                INFANT
                            }, identity),
                            departureDate,
                            ...restCriteria
                        }

                        if(tour.options.length) {
                            setCurrentBookingTour(tour)
                            nextPage()
                        } else {
                            setShowSoldOutModal(true)
                        }
                    }).catch(err => {
                        setShowServerErrorModal(true)
                    })
                })
            } else {
                setShowSoldOutModal(true)
            }
        })
    }

    const initialValues = React.useMemo(() => ({
        tourCode,
        ADULT: 1,
        CHILD: 0,
        INFANT: 0,
        // 'departure-date': moment().subtract(1, 'day').format('yyyy-MM-dd'),
        'departure-date': new Date(),
    }), [tourCode, TODAY_DATE])

    React.useEffect(() => {
        getAvailabilityForMonth({
            tourCode: tourCode,
            departureDate: moment(currentMonth).format('YYYY-MM-DD'),
            // The reason we ask for 2 months is that the Calendar library renders 2 months even when we
            // set "one month only" mode
            arrivalDate:  moment(currentMonth).add(1, 'month').endOf('month').format('YYYY-MM-DD')
        }).then(({ data }) => {

            // Map the response into an object like this:
            // {
            //     '2020-05-30': { ... },
            //     '2020-05-31': { ... },
            //     '2020-06-01': { ... },
            //     ...
            // }
            setAvailabilityPerDay(
                reduce(
                    data.getAvailabilityForRange,
                    (acc, entry) => ({
                        ...acc,
                        [moment(entry.startDate).format('YYYY-MM-DD')]: entry
                    }),
                    availabilityPerDay
                )
            )
        })
    }, [currentMonth])

    const handleMonthChange = (newMonth) => {
        setCurrentMonth(moment(newMonth).format('YYYY-MM-DD'))
    }

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={initialValues}
            mutators={{
                onDateChange: ([date], state, utils) => {
                    utils.changeValue(state, 'departure-date', (old) => moment(date).format('YYYY-MM-DD'))
                }
            }}
            render={({ handleSubmit, form: { mutators }, submitting, pristine, values }) => {
                const {
                    ADULT,
                    CHILD,
                    INFANT,
                    'departure-date': departureDate
                } = values

                const totalPassengers = ADULT + CHILD + INFANT
                // Note that we don't include infants here, bc they don't use a seat
                const totalSeats = totalPassengers - INFANT

                return (
                    <form className={styles.bookingForm} onSubmit={handleSubmit}>
                        <Typography className={styles.bookingBlockTitle} component="div">
                            From <span className={styles.bookingPrice}>${cmsTour.acf.price}</span> NZD
                        </Typography>

                        <span className={styles.priceGuarantee}>Lowest Price Guarantee</span>

                        <hr />

                        <Typography className={styles.bookingFormTitle} variant="h3" component="h5">
                            Select Travellers and Date
                        </Typography>

                        <div className="mb-4">
                            <FarePicker
                                label={`${totalPassengers} Passenger${totalPassengers > 1 ? 's' : ''}`}
                                fullWidth={true}
                                StartIcon={
                                    <Icon className={styles.formIcon} fontSize="small">
                                        <FaUser />
                                    </Icon>
                                }
                                ApplyBtn={
                                    <Button
                                        size="lg"
                                        block
                                        type="button"
                                    >
                                        Apply
                                    </Button>
                                }
                            >
                                <Field
                                    name="ADULT"
                                    id="adult-counter-id"
                                    label="Adult"
                                    sublabel="(Age 15+)"
                                    addLabel="Add adult"
                                    removeLabel="Remove adult"
                                    min={totalPassengers === 1 ? 1 : 0}
                                    max={15}
                                    fullWidth={true}
                                    render={({ input, meta, ...props }) => (
                                        <FareCounter {...input} {...meta} {...props} />
                                    )}
                                />

                                <Field
                                    name="CHILD"
                                    id="child-counter-id"
                                    label="Child"
                                    sublabel="(Age 5-14)"
                                    addLabel="Add child"
                                    removeLabel="Remove child"
                                    min={totalPassengers === 1 ? 1 : 0}
                                    max={15}
                                    fullWidth={true}
                                    render={({ input, meta, ...props }) => (
                                        <FareCounter {...input} {...meta} {...props} />
                                    )}
                                />

                                <Field
                                    name="INFANT"
                                    id="infant-counter-id"
                                    label="Infant - Free"
                                    sublabel="(Age 0-4)"
                                    addLabel="Add infant"
                                    removeLabel="Remove infant"
                                    min={totalPassengers === 1 ? 1 : 0}
                                    max={15}
                                    fullWidth={true}
                                    render={({ input, meta, ...props }) => (
                                        <FareCounter {...input} {...meta} {...props} />
                                    )}
                                />

                            </FarePicker>
                        </div>

                        <div className="mb-4 position-relative">
                            <DayPickerSingle.Provider
                                fieldName="departure-date"
                                onCalendarDateChange={mutators.onDateChange}
                            >
                                <Field
                                    name="departure-date"
                                    id="departure-date"
                                    type="text"
                                    fullWidth={true}
                                    render={({ input, meta, ...props }) => (
                                        <DayPicker
                                            textFormat='MMM DD, YYYY'
                                            StartIcon={
                                                <Icon>
                                                    <FaRegCalendarAlt />
                                                </Icon>
                                            }
                                            {...input}
                                            {...meta}
                                            {...props}
                                        />
                                    )}
                                />

                                <DayPickerSingle.Calendar
                                    className={styles.calendarWrapper}
                                    fallbackClassName={styles.fallbackCalendarWrapper}
                                    // isDayBlocked={(date) => {
                                    //     return moment(date).isBefore( moment().startOf('day') )
                                    // }}
                                    date={departureDate}
                                    daySize={41}
                                    onNextMonthClick={handleMonthChange}
                                    onPrevMonthClick={handleMonthChange}
                                    // onOutsideClick={function noRefCheck(){}}
                                    renderDayContents={(dateMoment) => {
                                        return (
                                            // <div
                                            //     className={cx(
                                            //         styles.calendarDay,
                                            //         isSoldOut(dateMoment) && styles.soldOut,
                                            //         isSellingFast(dateMoment) && styles.sellingFast
                                            //     )}
                                            // >
                                            <div
                                                className={cx(
                                                    styles.calendarDay
                                                )}
                                            >
                                                {dateMoment.date()}
                                            </div>
                                        )
                                    }}
                                    renderCalendarInfo={() => {
                                        return (
                                            <div className={styles.calendarInfo}>
                                                <div><span className={cx(styles.calendarInfoColor, styles.calendarInfoSelling)} /> Selling fast</div>
                                                <div><span className={cx(styles.calendarInfoColor, styles.calendarInfoSold)} /> Sold out</div>
                                                <div><span className={cx(styles.calendarInfoColor, styles.calendarInfoSelected)} /> Selected</div>
                                            </div>
                                        )
                                    }}
                                />
                            </DayPickerSingle.Provider>
                        </div>

                        {/* TODO:: FIX_MUBASHIR_ALI */}
                        <BookingWizard.Button
                            size="lg"
                            block
                            type="submit"
                            // disabled={submitting || !(departureDate && (totalPassengers))}
                        >
                            Book now
                        </BookingWizard.Button>

                        <div className={styles.formCancellation}>
                            <b>Free Cancellation</b><br />
                            up to 2 hours in advance
                        </div>

                        <Modal
                            dialogClassName={styles.modalDialog}
                            show={showSoldOutModal}
                            onHide={() => setShowSoldOutModal(false)}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title className={styles.modalTitle}>
                                    Sorry, this date is sold out {totalSeats > 1 && `for ${totalSeats} travelers`}
                                    {!!relatedTours.length &&
                                    <p className={styles.modalSubtitle}>
                                        Here's a similar experience that could still be available on {moment(departureDate).format('MMM DD, YYYY')}.
                                    </p>
                                    }
                                </Modal.Title>
                            </Modal.Header>

                            {!!relatedTours.length ?
                            <>
                                <Modal.Body>
                                <TourCards aside={true}>
                                    <TourCard
                                        {...relatedTours[0]}
                                        className={styles.tourCard}
                                    />
                                </TourCards>

                                </Modal.Body>

                                <Modal.Footer className="d-flex justify-content-between">
                                    <a href="#" onClick={() => setShowSoldOutModal(false)}>Go back and try another date</a>
                                    <Link to={destination.url}>See more {destination.title}</Link>
                                </Modal.Footer>
                            </>
                            :
                            <Modal.Body className="pt-4 pb-4">
                                <Button className="text-uppercase" variant="primary" size="lg" href="#" onClick={() => setShowSoldOutModal(false)}>
                                    Book for another day
                                </Button >
                            </Modal.Body>
                            }
                        </Modal>

                        <ServerErrorModal show={showServerErrorModal} />
                    </form>
                )
            }}
        />
    )
}

export default BookingStepSearch
