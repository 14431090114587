import React                  from 'react'
import cx                     from 'utils/classnames'
import {
    START_DATE,
}                             from 'react-dates/constants'
import gql                    from 'graphql-tag'
import moment                 from 'moment'
import { useQuery }           from '@apollo/react-hooks'
import {
    Field,
    Form,
    FormSpy,
}                             from 'react-final-form'
import {
    FaUser,
    FaRegCalendarAlt
}                             from 'react-icons/fa'
import {
    Button,
}                             from 'react-bootstrap'
// import {
//     useContext,
//     getContextState
// }                             from 'entrada-ui/utils'
import Typography             from 'entrada-ui/Typography'
import Icon                   from 'entrada-ui/Icon'
import Wizard                   from 'components/Wizard'
import BookingStepSearch      from './BookingStepSearch'
import BookStepOptions        from './BookStepOptions'
import styles                 from './styles.module.scss'

const STEPS = {
    SEARCH: 0,
    OPTIONS: 1,
    DETAILS: 2,
    CONFIRM: 3
}

const FARES = {
    ADULT: ['Adult', 'Adults'],
    CHILD: ['Child', 'Children'],
    INFANT: ['Infant', 'Infants'],
}

const FareCodeToText = (fareCode, plural = false) => {
    return FARES[fareCode][plural ? 1 : 0]
}

const BookingWizardContext = React.createContext()

const BookingWizard = React.forwardRef(function BookingWizard(props, ref) {

    const {
        className,
        tourCode,
        tourSellingFast,
        destination,
        relatedTours = [],
        price,
        cmsTour,
        onPageChange = (page) => {}
    } = props

    const [currentBookingTour, setCurrentBookingTourData] = React.useState(null)

    const setCurrentBookingTour = React.useCallback((tour) => {
        window.localStorage.setItem('currentBookingTour', JSON.stringify(tour))

        setCurrentBookingTourData(tour)
    }, [setCurrentBookingTourData])

    const [currentPage, setcurrentPage] = React.useState(STEPS.SEARCH)

    React.useEffect(() => {
        onPageChange(currentPage)
    }, [currentPage])

    const nextPage = React.useCallback(() => setcurrentPage(currentPage + 1), [setcurrentPage, currentPage])

    const resetBookingSearch = React.useCallback(() => {
        window.localStorage.removeItem('currentBookingTour')
        setCurrentBookingTourData(null)
        setcurrentPage(0)
    }, [setcurrentPage, setCurrentBookingTourData])

    const childContext = React.useMemo(() => ({
        tourCode,
        tourSellingFast,
        destination,
        relatedTours,
        price,
        currentPage,
        nextPage,
        currentBookingTour,
        setCurrentBookingTour,
        resetBookingSearch,
        relatedTours,
        cmsTour
    }), [tourCode, price, currentPage, nextPage, currentBookingTour, setCurrentBookingTour, resetBookingSearch])

    return (
        <div ref={ref} className={cx(styles.bookingWizard, className)}>
            <BookingWizardContext.Provider value={childContext}>
                <Wizard currentPage={currentPage}>
                    <Wizard.Page>
                        <BookingStepSearch />
                    </Wizard.Page>

                    <Wizard.Page>
                        <BookStepOptions />
                    </Wizard.Page>
                </Wizard>
            </BookingWizardContext.Provider>
        </div>
    )
})

BookingWizard.Context = BookingWizardContext


BookingWizard.STEPS = STEPS
BookingWizard.Button = ({ className, ...props }) => (
    <Button variant="primary" className={cx(className)} {...props} />
)

BookingWizard.Block = ({ className, ...props }) => (
    <div className={cx(styles.block, className)} {...props} />
)

BookingWizard.Block.Title = ({ className, ...props }) => (
    <>
        <Typography className={cx('mt-0 mb-1', className)} variant="h4" color="brand-color" {...props} />
        <hr />
    </>
)

export { FareCodeToText }

export default BookingWizard
