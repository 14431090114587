import React                  from 'react'
import {
    useContext,
}                             from 'entrada-ui/utils'
import TextField              from 'entrada-ui/TextField'
import DayPickerSingleContext from '../DayPickerSingleContext'
import styles                 from './styles.module.scss'
import moment from 'moment'

const DayPickerSingleInput = ({
    StartIcon,
    onFocus,
    textFormat = 'YYYY-MM-DD',
    value,
    ...props
}) => {
    const context = useContext(DayPickerSingleContext)

    const handleFocus = React.useCallback((ev) => {
        if(context) {
            context.onFocusDateInput()
        }

        if(onFocus) {
            onFocus(ev)
        }
    }, [context, onFocus])

    return (
        <TextField
            {...props}
            value={value ? moment(value).format(textFormat) : undefined}
            InputProps={{
                classes: {
                    root: styles.root
                }
            }}
            StartIcon={StartIcon}
            onFocus={handleFocus}
            onChange={undefined}
            readOnly
        />
    )
}

DayPickerSingleInput.propTypes = {
    ...TextField.propTypes,
}

export default DayPickerSingleInput
