import React                  from 'react'
import PropTypes              from 'utils/prop-types'
import { includes }           from 'lodash-es'
import {
    START_DATE,
    END_DATE
}                             from 'react-dates/constants'
import {
    useContext,
    getContextState
}                             from 'entrada-ui/utils'
import TextField              from 'entrada-ui/TextField'
import DayPickerRangeContext  from '../DayPickerRangeContext'
import DayPickerRangeCalendar from '../DayPickerRangeCalendar'
import styles                 from './styles.module.scss'

const DayPickerRangeInput = ({
    name,
    start,
    end,
    onFocus,
    CalendarProps,
    ...props
}) => {
    const context = useContext(DayPickerRangeContext)
    const {
        startDate,
        endDate,
        isCalendarControlled,
        setFormControlRef
    } = getContextState({
        props,
        states: ['startDate', 'endDate', 'isCalendarControlled', 'setFormControlRef'],
        context
    })

    const dateFieldName = start ? START_DATE : END_DATE

    const [isOpen, setIsOpen] = React.useState(false)
    const formControlRef = React.useRef()

    React.useEffect(() => {
        setFormControlRef(formControlRef)
    })

    const handleFocus = React.useCallback((ev) => {
        if(context) {
            context.onFocusDateInput(dateFieldName)
        }

        if(!isCalendarControlled) {
            setIsOpen(true)
        }

        if(onFocus) {
            onFocus(ev)
        }
    }, [context, onFocus, dateFieldName, isCalendarControlled])

    // Methods for non-controlled Calendar

    const handleCalendarDatesChange = React.useCallback((ev) => {
        setIsOpen(false)
    }, [])

    const handleOutsideClick = (ev) => {
        if(!includes([name], ev.target.name)) {
            setIsOpen(false)
        }
    }

    return (
        <>
            <TextField
                {...props}
                name={name}
                formControlRef={formControlRef}
                InputProps={{
                    classes: {
                        root: styles.root
                    }
                }}
                onFocus={handleFocus}
                onChange={undefined}
                readOnly
            />
            {!isCalendarControlled &&
            <DayPickerRangeCalendar
                {...CalendarProps}
                formControlRef={formControlRef}
                isOpen={isOpen}
                startDate={startDate}
                endDate={endDate}
                focusedInput={dateFieldName}
                onCalendarDatesChange={handleCalendarDatesChange}
                onOutsideClick={handleOutsideClick}
            />
            }
        </>
    )
}

DayPickerRangeInput.propTypes = {
    ...TextField.propTypes,
    start: PropTypes.bool,
    end: PropTypes.bool,
}

DayPickerRangeInput.defaultProps = {
    CalendarProp: {}
}

export default DayPickerRangeInput
