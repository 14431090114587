import React                  from 'react'

const Wizard = ({ currentPage, children }) => (
    React.Children.map(children, (child, index) => (
        currentPage === index ? child : undefined
    ))
)

Wizard.Page = ({ children }) => children

export default Wizard
