import React                  from 'react'
import { navigate }           from 'gatsby'
import {
    capitalize,
    clone,
    compact,
    filter,
    find,
    forEach,
    identity,
    isEmpty,
    keyBy,
    keys,
    pickBy,
    map,
    mapValues,
    merge,
    reduce,
    groupBy,
}                             from 'lodash-es'
import {
    Form,
    Field,
    FormSpy
}                             from 'react-final-form'
import {
    Col,
    Container,
    OverlayTrigger,
    Row,
    Tooltip
}                             from 'react-bootstrap'
import { FaQuestionCircle }   from 'react-icons/fa'
import cx                     from 'utils/classnames'
import {
    useContext,
    getContextState
}                             from 'entrada-ui/utils'
import Checkbox               from 'entrada-ui/Checkbox'
import FareCounter            from 'entrada-ui/FareCounter'
import Icon                   from 'entrada-ui/Icon'
import Link                   from 'components/Link'
import Select                 from 'entrada-ui/Select'
import Typography             from 'entrada-ui/Typography'
import CartHandler            from 'components/CartHandler'
import Modal                  from 'components/Modal'
import BookingWizard          from '../'
import { FareCodeToText }     from '../'
import styles                 from './styles.module.scss'

const groupTitle = (groupType) => {
    switch (groupType) {
        case 'meal':
            return 'Meals';
        default:
            return `Add ${groupType}`
            break;
    }
}

const OptionTitle = ({ option, children }) => {
    const [show, setShow] = React.useState(false)
    const target = React.useRef(null)

    return (
        <div className="mt-0 mb-3 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center justify-content-between">
                {/* <Typography className="mt-0 mb-0" variant="h5"> */}
                <Typography className="mt-0 mb-0" component="div">
                    {children} {option && option.type === 'AUTOMATICALLY_INCLUDED' && '(INCLUDED)'}
                    {/* {option && option.description &&
                    <OverlayTrigger
                        placement="top"
                        overlay={
                        <Tooltip id={`tooltip-${option.id}`}>
                            {option.description}
                        </Tooltip>
                        }
                    >
                        <Icon className={styles.questionIcon} ref={target} fontSize="small">
                            <FaQuestionCircle />
                        </Icon>
                    </OverlayTrigger>
                    } */}
                </Typography>
            </div>


            {option &&
            <OptionCheckbox name={`selectedOptions.${option.id}`} forceChecked={option && option.type === 'AUTOMATICALLY_INCLUDED'} />
            }
        </div>
    )
}

const OptionCheckbox = ({ name, disabled, forceChecked }) => (
    <Field
        name={name}
        render={({ input, meta, ...props }) => {
            return (
                <Checkbox {...input} {...meta} {...props} value={forceChecked ? true : input.value} />
            )
        }}
    />
)


const BookStepOptions = (props) => {
    const Block = BookingWizard.Block
    const Button = BookingWizard.Button

    const [showSubmitError, setShowSubmitError] = React.useState(false)
    const [submittedForm, setSubmittedForm] = React.useState(null)
    const [total, setTotal] = React.useState(0)

    const bookingContext = useContext(BookingWizard.Context)
    const {
        resetBookingSearch,
        tourCode,
        cmsTour,
        currentBookingTour: {
            options,
            pickups,
            dropoffs,
            pricing,
            searchCriteria,
            fares,
            tourName,
            tourDurationHours,
            tourDurationMinutes,
            cardImage
        }
    } = bookingContext

    const pickupOptions = React.useMemo(() => (
        map(pickups, (pickup) => ({
            label: `${pickup.name} ${pickup.time}`,
            value: pickup.code
        }))
    ), [pickups])

    const dropoffOptions = React.useMemo(() => (
        map(dropoffs, (dropoff) => ({
            label: `${dropoff.name} ${dropoff.time}`,
            value: dropoff.code
        }))
    ), [dropoffs])

    const context = useContext(CartHandler.Context)
    const { bookTour } = context

    let groupedOptions = groupBy(options, 'category')
    const includedOptions = []
    groupedOptions = mapValues(groupedOptions, (options) => {
        const optionals = []
        options.forEach(option => {
            if(option.type === 'AUTOMATICALLY_INCLUDED') {
                includedOptions.push(option)
            } else {
                optionals.push(option)
            }

            // Only accept Adult, Child and Infant fare types
            option.prices = filter(option.prices, price => (
                ['ADULT', 'CHILD', 'INFANT'].includes(price.passengerType)
            ))
        })

        return [
            ...includedOptions,
            ...optionals
        ]
    })


    const handleSubmit = React.useCallback(async (data) => {
        setSubmittedForm(data)
        const { options: formOptions, selectedOptions, pickup: pickupOption, dropoff: dropoffOption } = data
        const includedoptionIds = map(includedOptions, 'code')



        forEach(formOptions, (option, optionId) => {
            if(isEmpty(option)) {
                formOptions[optionId] = searchCriteria.searchFares
            }
        })

        const finalOptions = compact(map(
            formOptions,
            (passengerComposition, optionId) => {
                if(includedoptionIds.includes(optionId) || selectedOptions[optionId]) {
                    return {
                        optionID: optionId,
                        code: find(options, {id: optionId}).passengerType,
                        numAdults: passengerComposition['ADULT'] || 0,
                        numChildren: passengerComposition['CHILD'] || 0,
                        numInfants: passengerComposition['INFANT'] || 0
                    }
                }
            },
        ))

        const pickup = pickupOption ? find(pickups, { code: pickupOption.value }) : undefined
        const dropoff = dropoffOption ? find(dropoffs, { code: dropoffOption.value }) : undefined
        const { searchFares, departureDate } = searchCriteria

        bookTour({
            tourCode,
            departureDate,
            numAdults: searchFares['ADULT'],
            numChildren: searchFares['CHILD'],
            numInfants: searchFares['INFANT'],
            options: finalOptions,
            // pickupID: pickup.id,
            // dropoffID: dropoff.id,

            // Frontend specific
            tourName,
            pickup,
            dropoff,
            tourDurationHours,
            tourDurationMinutes,
            cardImage,
            fares

        }).then((res) => {
            if(res && res.errors && res.errors.length) {
                throw res.errors
            } else {
                navigate('/cart')
            }
        }).catch((err) => {
            setShowSubmitError(true)
        })
    })

    const handleTryAgain = React.useCallback(async () => {
        setShowSubmitError(false)
        handleSubmit(submittedForm)
    }, [submittedForm, setShowSubmitError, handleSubmit])

    const handleResetSearch = (ev) => {
        setShowSubmitError(false)
        resetBookingSearch()
    }

    const initialValues = React.useMemo(() => {
        const values = {
            options: {},
            selectedOptions: {}
        }

        // Experiences and meals
        options.forEach((option) => {
            // Only create keys for fares that were selected on the Search step
            const searchFareKeys = keys(searchCriteria.searchFares)
            values.options[option.id] = {}
            option.prices.forEach((price) => {
                if(searchFareKeys.includes(price.passengerType)) {
                    // Use the selected number of passengers as default value for the options
                    values.options[option.id][price.passengerType] = searchCriteria.searchFares[price.passengerType]
                }
            })
        })

        // Pickups/Dropoffs
        values.pickup = pickupOptions[0]
        values.dropoff = dropoffOptions[0]

        return values
    }, [options, pickupOptions])

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={initialValues}
            groupedOptions={groupedOptions}
            searchCriteria={searchCriteria}
            render={({ groupedOptions, handleSubmit, submitting, pristine, values }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Typography className={styles.bookingBlockTitle} component="div">
                            <span className={styles.bookingPrice}>Add Extras</span>
                        </Typography>

                        {map(groupedOptions, (options, groupType) => (
                            <Block key={groupType} className="mb-4">
                                <Block.Title>{groupTitle(groupType)}</Block.Title>

                                {map(options, (option, optionIdx) => {
                                    // We only need to count Adults and Children, bc Infants are free
                                    const { ADULT = 0, CHILD = 0 } = values.options[option.id]
                                    const totalPassengers = ADULT + CHILD

                                    return (
                                        <div className="mb-3" key={optionIdx}>
                                            <OptionTitle option={option}>
                                                {option.description || 'MISSING TITLE'}
                                            </OptionTitle>

                                            {values.selectedOptions[option.id] &&
                                            map(option.prices, (fare, fareIdx) => {
                                                // If the fare type wasn't selected on the search step we don't
                                                // display it here.
                                                if(!searchCriteria.searchFares[fare.passengerType]) {
                                                    return
                                                }

                                                // Get the number of Passengers selected for this fare in the search step
                                                // that will be the maximum number por the Options.
                                                const fareMaxPassengers = searchCriteria.searchFares[fare.passengerType]

                                                const FARE_TYPE_LABELS = {
                                                    'ADULT': 'Adult',
                                                    'CHILD': 'Child',
                                                    'INFANT': 'Infant'
                                                }

                                                return (
                                                    <Field
                                                        key={fareIdx}
                                                        name={`options.${option.id}.${fare.passengerType}`}
                                                        id={`${option.id}-${fare.passengerType}-counter-id`}
                                                        label={`${FARE_TYPE_LABELS[fare.passengerType]} (${fare.price ? `${fare.priceLabel || '$'}${fare.price}` : 'FREE'}) `}
                                                        addLabel={`Add ${FARE_TYPE_LABELS[fare.passengerType]}`}
                                                        removeLabel={`Remove ${FARE_TYPE_LABELS[fare.passengerType]}`}
                                                        min={totalPassengers === 1 ? 1 : 0}
                                                        max={fareMaxPassengers}
                                                        fullWidth={true}
                                                        render={({ input, meta, ...props }) => (
                                                            <FareCounter {...input} {...meta} {...props} />
                                                        )}
                                                    />
                                                    )
                                            })
                                            }

                                        </div>
                                    )
                                })}
                            </Block>
                        ))}

                        {(!!pickupOptions.length || !!dropoffOptions.length) &&
                        <Block className="mb-4">
                            <Block.Title>Free Hotel Pickup or Drop-off</Block.Title>

                            {!!pickupOptions.length &&
                            <div className="mb-3">
                                <OptionTitle>
                                    Select hotel pickup below
                                </OptionTitle>

                                <Field
                                    name="pickup"
                                    options={pickupOptions}
                                    clearable={false}
                                    fullWidth={true}
                                    render={({ input, meta, ...props }) => (
                                        <Select {...input} {...meta} {...props} />
                                    )}
                                />
                            </div>
                            }

                            {!!dropoffOptions.length &&
                            <div className="mb-3">
                                <OptionTitle>
                                    Select hotel drop-off below
                                </OptionTitle>

                                <Field
                                    name="dropoff"
                                    options={dropoffOptions}
                                    clearable={false}
                                    fullWidth={true}
                                    render={({ input, meta, ...props }) => (
                                        <Select {...input} {...meta} {...props} />
                                    )}
                                />
                            </div>
                            }
                        </Block>
                        }

                        <Row className="mb-4">
                            <Col sm="12" className={styles.bookingBottomSection}>
                                <FormSpy
                                    subscription={{ values: true }}
                                    render={({ values }) => {
                                        let total = 0
                                        const textsTotal = []

                                        // Calculate the base price of the booking based on the passenger composition.
                                        total += reduce(
                                            searchCriteria.searchFares,
                                            (acc, numberOfPassengers, fareCode) => {
                                                // - Generate base price description based on the composition and price
                                                textsTotal.push(
                                                    `${fareCode === 'INFANT' ? 'FREE' : `NZ $${pricing[fareCode].toFixed(2)}`} x${numberOfPassengers} ${FareCodeToText(fareCode, numberOfPassengers > 1)}`
                                                )

                                                return fareCode === 'INFANT' ? acc : acc + pricing[fareCode] * numberOfPassengers
                                            },
                                            0
                                        )

                                        const optionsByCode = keyBy(options, 'id')
                                        const selectedOptionIds = keys(values.selectedOptions)

                                        // - Calculate the total for the Options
                                        // - Generate invoice descriptions based on the composition
                                        forEach(selectedOptionIds, (selectedOptionId) => {
                                            const optionData = find(options, { id: selectedOptionId })
                                            const optionComposition = values.options[selectedOptionId]

                                            forEach(optionComposition, (numberOfPassengers, fareCode) => {
                                                const pricingData = find(optionData.prices, { passengerType: fareCode })

                                                // Check if the option is currently selected
                                                if(values.selectedOptions[selectedOptionId]) {
                                                    const optionTotal = pricingData.price * numberOfPassengers
                                                    total += optionTotal
                                                    if(numberOfPassengers) {
                                                        textsTotal.push(
                                                            `${optionData.description} x${numberOfPassengers} ${FareCodeToText(fareCode, numberOfPassengers > 1)}`
                                                        )
                                                    }
                                                }
                                            })
                                        })

                                        setTotal(total)

                                        return (
                                            <>
                                                <p className={styles.totalSummary}>
                                                    ({textsTotal.join(', ')})
                                                </p>
                                                <Typography className={cx("mt-0 mb-0", styles.totalTitle)} variant="h4" component="h6">
                                                    Total
                                                </Typography>
                                                <div className={styles.total}>
                                                    NZ ${total.toFixed(2)}
                                                </div>
                                            </>
                                        )
                                    }}
                                />

                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" className={styles.bookingBottomSection}>
                                {/* TODO:: FIX_MUBASHIR_ALI */}
                                {/* USE THIS */}
                                <Button
                                    size="lg"
                                    block
                                    type="submit"
                                >
                                    CONTINUE
                                </Button>
                                

                                {/* <Link className={cx('btn btn-primary btn-block btn-lg', styles.submit)} to="/cart">
                                    Continue
                                </Link> */}
                            </Col>
                        </Row>

                        <Modal
                            show={showSubmitError}
                            onHide={() => setShowSubmitError(false)}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    Sorry, something went wrong! You can go back and try again
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Footer className="d-flex justify-content-between">
                                <a href="#" onClick={() => handleResetSearch()}>Start a new Booking</a>
                                <Button onClick={handleTryAgain}>Try again</Button>
                            </Modal.Footer>
                        </Modal>
                    </form>
                )
            }}
        />
    )
}

export default BookStepOptions
