import React                from 'react'
import PickerInput          from 'entrada-ui/PickerInput'

const FarePicker = ({children, ...props}) => (
    <PickerInput {...props} aria-label="Total passengers">
        {children}
    </PickerInput>
)

export default FarePicker
